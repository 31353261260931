<script setup lang="ts">
// Imports
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';

// ---------------------------------------
// Hooks
// ---------------------------------------
const { t } = useI18n();
const colorMode = useColorMode();
const { replace } = useRouter();
const { $service } = useNuxtApp();
const store = useUserStore();
const { name, info } = storeToRefs(store);


// ---------------------------------------
// State
// ---------------------------------------
const links = computed(() => [
  {
  label: t('PROFILE'),
  icon: 'i-heroicons-user-circle',
  to: '/settings/profile',
}, {
  label: t('PASSWORD'),
  icon: 'i-heroicons-lock-closed',
  to: '/settings/password',
  
}, {
  label: t('PREFERENCES'),
  icon: 'i-heroicons-users',
  to: '/settings/preferences',
}]);

const linksMobile = computed(() => links.value.map((l) => ({ ...l, click: toggleMnMb })));

const isOpen = ref(false);

const items = computed(() => [
  [{
    label: info.value?.email || '',
    slot: 'account',
    disabled: true
  }],
  [{
    label: t('SETTINGS'),
    icon: 'i-heroicons-cog-8-tooth',
    click: () => replace('/settings/profile')
  }],
  [{
    label: t('SIGN_OUT'),
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: signOut,
  }]
]);

function signOut() {
  $service.auth.signOut();

  replace({
    path: '/sign_in',
    replace: true
  });
}

function toggleMnMb() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <div>
    <nuxt-link to="/">
      <img :src="`/assets/${colorMode.value === 'light' ? 'logo-dark.png' : 'logo.webp'}`" alt="Terreiro Vó Rita das Almas logo" class="logo my-2 block">
    </nuxt-link>

    <div class="block mt-9">
      <span class="block mb-4 px-2 text-gray-400/80">{{ $t('USER_SETTINGS') }}</span>
      <UVerticalNavigation :links="links" class=""/>
    </div>

    <ClientOnly>
      <Teleport to="#mobile_menu">
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-heroicons-bars-3-solid"
          @click="toggleMnMb"
        />
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <Teleport to="body">
        <USlideover v-model="isOpen" side="left">
          <div class="p-4 flex-1">
            <div class="flex justify-between justify-items-center">
              <nuxt-link to="/" @click="toggleMnMb">
                <img :src="`/assets/${colorMode.value === 'light' ? 'logo-dark.png' : 'logo.webp'}`" alt="Terreiro Vó Rita das Almas logo" class="logo my-2 block">
              </nuxt-link>
              
              <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="self-center" @click="isOpen = false" />
            </div>
            <UVerticalNavigation :links="linksMobile" class="mt-9"/>
          </div>
        </USlideover>
      </Teleport>
    </ClientOnly>
  </div>
</template>
<style lang="scss" scoped>
.logo {
  width: 140px;
}
</style>
